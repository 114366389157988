import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import { ThemeProvider } from '../toggle/ThemeContext';
import ToggleBtn from '../toggle/ToggleBtn';
import CookieWarningBanner from '../cookie/CookieWarningBanner';
import * as FaIcons from "react-icons/fa6";

function Navbar() {

    return (
    <div className=''>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
            <use xlinkHref="#gentle-wave" x="28" y="0" fill="rgba(0,140,255,0.8)" />
            <use xlinkHref="#gentle-wave" x="28" y="3" fill="rgba(255,76,0,0.8)" />
            <use xlinkHref="#gentle-wave" x="28" y="5" fill="rgba(0,140,255,0.8)" />
            <use xlinkHref="#gentle-wave" x="28" y="9" fill="rgba(255,76,0,1.0)" />
            </g>
        </svg>
        
        <footer className='footer'>
            <div className="container">
                <div className="row text-center">
                    <div className="col-lg-6 text-lg-left order-lg-0 order-12">
                        &copy; 2024 Brey Digital LLP
                    </div>
                    <div className="col-lg-6 text-lg-right">
                        <a className=" " href="mailto:hi@brey.in">hi@brey.in</a>
                    </div>
                </div>
            </div>
        </footer>
        <ThemeProvider>
            <ToggleBtn />
        </ThemeProvider>
        <CookieWarningBanner />
    </div>
    )
}

export default Navbar
