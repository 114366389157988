import React from 'react'
import {BrowserRouter as Router, Routes, Route} from  'react-router-dom'
import './css/main.css'
import './css/style.css'
import './css/footer.css'
import Navbar from './header/Navbar';
import Footer from './footer/Footer';
import Home from './pages/home/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Sitemap from './sitemap/Sitemap'

function Index() {
    return (
        <Router>
            <Navbar />
                <div style={{marginTop:'120px'}}>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/work' element={<Services />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/sitemap' element={<Sitemap />} />
                </Routes>
                </div>
            <Footer />
        </Router>
    )
}

export default Index