import React from 'react'

function HeroSection() {

    return (
        <section id="home" className="home">
            <div className="container text-center">
                <h1 className='mb-0'>At Brey Digital,</h1>
                <h2>We Assist StartUps</h2>
                <h3>to <strong>stand</strong> out</h3>
                <div className="text-center">
                Get in touch at <a className="btn" href="mailto:hi@brey.in">hi@brey.in</a>
                {/* or <a className="" href="https://calendly.com/shubhamtripathi/30min" target="_blank" rel="noreferrer">Book A Free Consultation</a> */}
                </div>
            </div>
        </section>
    )
}

export default HeroSection
