import React, { useEffect } from 'react'
import { Helmet } from "react-helmet-async";
import ServicesList from './services/Serviceslist';

function Services() {

    // useEffect(() => {
    //     document.title = "Work - Brey Digital"
    // }, []);

    const title = `Services - Brey Digital LLP`
    const desc = `We’re a design studio focused on creating brands and digital products. We create innovative digital products & future-oriented brands. We design for brands, we work for people.`

    return (
        <section id="work" className="work">
            <Helmet>
                <title>{`${title}`}</title>
                <meta name="title" content={`${title}`}/>
                <meta name="description" content={`${desc}`} />
                <meta property="og:title" content={`${title}`}/>
                <meta property="og:description" content={`${desc}`}/>
                <meta property="og:url" content="https://brey.in/work"/>
                <link rel="canonical" href="https://brey.in/work"/>
                <meta property="og:site_name" content="Brey Digital LLP"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className="container">
                <div className="section-title">
                    <h2>Services</h2>
                </div>
                {/* <ServicesList /> */}
                <div className="row d-flex align-items-start">
                    <div className="col-lg-4 text-left">
                        <h3>Design</h3>
                        <ul>
                            <li>Brand Nomenclature</li>
                            <li>Brand Identity</li>
                            <li>Graphic Design</li>
                            <li>Web/UI Design</li>
                            <li>Packaging Design</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 text-left">
                        <h3>Development</h3>
                        <ul>
                            <li>Website Development</li>
                            <li>App Development</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 text-left">
                        <h3>Others</h3>
                        <ul>
                            <li>Brand Strategy</li>
                            <li>Social Media Management</li>
                            <li>Social Media Marketing</li>
                            <li>Copywriting</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services
