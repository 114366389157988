import React from 'react'

function Sitemap() {

    return (
        <section id="home" className="home">
            <div className="container">
                <h2>Sitemap</h2>
                <ul>
                    <li>
                        <a href="https://brey.in/" target="_blank">https://brey.in/</a>
                    </li>
                    <li>
                        <a href="https://brey.in/about" target="_blank">https://brey.in/about</a>
                    </li>
                    <li>
                        <a href="https://brey.in/work" target="_blank">https://brey.in/work</a>
                    </li>
                    <li>
                        <a href="https://brey.in/contact" target="_blank">https://brey.in/contact</a>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Sitemap