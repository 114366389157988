import React from 'react'
import { Helmet } from "react-helmet-async";
import * as FaIcons from "react-icons/fa6";

function Contact() {

    // useEffect(() => {
    //     document.title = "Contact - Brey Digital"
    // }, []);

    const title = `Contact - Brey Digital LLP`
    const desc = `Email Us at hi@brey.in. Or, WhatsApp/Call us at +91-995-865-4488`

    return (
        <section id="about" className="about">
            <Helmet>
                <title>{`${title}`}</title>
                <meta name="title" content={`${title}`}/>
                <meta name="description" content={`${desc}`} />
                <meta property="og:title" content={`${title}`}/>
                <meta property="og:description" content={`${desc}`}/>
                <meta property="og:url" content="https://brey.in/contact"/>
                <link rel="canonical" href="https://brey.in/contact"/>
                <meta property="og:site_name" content="Brey Digital LLP"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className="container text-center">
                <div className="section-title mb-3">
                    <h2>Contact</h2>
                </div>
                        <p className='m-0'>Email<br /><a className="btn" href="mailto:hi@brey.in" target="_blank">hi@brey.in</a><br /><br />
                        </p>
                        <p className='m-0'>WhatsApp<br /><a className="btn" href="https://wa.me/919958654488" target="_blank">+91 995 865 4488</a>
                        </p>

                <div className='mt-3 mb-2'>
                    {/* Or, Connect via&nbsp; */}
                    <a href="https://www.linkedin.com/company/breyhq" target="_blank"><FaIcons.FaLinkedinIn /></a>&nbsp;
                    {/* &#9644;&nbsp; */}
                    <a href="https://instagram.com/breyhq" target="_blank"><FaIcons.FaInstagram/></a>
                </div>
            </div>

        </section>
    )
}

export default Contact