import React from 'react';
import Index from './Index';
import { ThemeProvider } from './theme/ThemeContext';

const Main = () => {
	return (
		<ThemeProvider>
		<div>
			<Index />
		</div>
		</ThemeProvider>
	)
}

export default Main