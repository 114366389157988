import React from 'react'
import { Helmet } from "react-helmet-async";
import img1 from "../media/Brey_pp.png"

function About() {

    // useEffect(() => {
    //     document.title = "About - Brey Digital"
    // }, []);

    const title = `About - Brey Digital LLP`
    const desc = `We’re a design studio focused on creating brands and digital products. We create innovative digital products & future-oriented brands. We design for brands, we work for people.`

    return (
        <section id="about" className="about">
            <Helmet>
                <title>{`${title}`}</title>
                <meta name="title" content={`${title}`}/>
                <meta name="description" content={`${desc}`} />
                <meta property="og:title" content={`${title}`}/>
                <meta property="og:description" content={`${desc}`}/>
                <meta property="og:url" content="https://brey.in/about"/>
                <link rel="canonical" href="https://brey.in/about"/>
                <meta property="og:site_name" content="Brey Digital LLP"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className="container">
                {/* <img className="mb-5 mx-auto d-block rounded" src={img1} alt="" /> */}
                <div className="section-title">
                    <h2>About Brey</h2>
                </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 text-lg-right">
                            <h2>We build unique brand identities.</h2>
                        </div>
                        <div className="col-lg-6 text-lg-left">
                            <p className="lead">Brey Digital LLP a design studio focused on creating brands and digital products. We help brands with user oriented designs and build innovative digital products.</p>
                            <p className="lead">With our bespoke services, we create immersive and enriching digital experiences for brands and individuals.</p>
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default About
