import React, { useState, useEffect } from 'react';
import * as FaIcons from "react-icons/fa6";
import './cookiewarningbanner.css';

const CookieWarningBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (cookieConsent) {
            setShowBanner(false);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setShowBanner(false);
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setShowBanner(false);
    };

    const handleClose = () => {
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
    <div className="cookie-banner">
        {/* <p className="cookie-banner__title">&#127850; Cookie Policy</p> */}
        {/* <div onClick={handleClose} className="cookie-banner-cross">x</div> */}
        <p className="cookie-banner__text">
            This website uses cookies to enhance your browsing experience.
            {/* By clicking "Accept", you consent to our use of cookies. */}
        </p>
        <div className="cookie-banner__actions">
            <div onClick={handleAccept} className="cookie-banner__button cookie-banner__button--accept">Got it</div>
            {/* <div onClick={handleReject} className="cookie-banner__button cookie-banner__button--reject">Reject</div> */}
        </div>
    </div>
  );
};

export default CookieWarningBanner;