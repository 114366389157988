import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from './ThemeContext';
import * as FaIcons from "react-icons/fa6";
import './toggle.css';

const ToggleBtn = () => {

    const { isDarkMode, toggleTheme } = useTheme();

    const [isOpen, setIsOpen] = useState(false);
    const menuTriggerRef = useRef(null);
    const cornerMenuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
            !menuTriggerRef.current.contains(event.target) &&
            !cornerMenuRef.current.contains(event.target)
            ) {
            setIsOpen(false);
            }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
        document.removeEventListener('click', handleClickOutside);
    };
    }, []);

    return (
    <>
        <div ref={menuTriggerRef} className={`menu-trigger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}></div>
        <div ref={cornerMenuRef} className={`corner-menu ${isOpen ? 'active' : ''}`}>
            <div className="menu-item wa">
                <a className="footIcon" href="https://wa.me/919958654488" target='_blank'><FaIcons.FaWhatsapp /></a>
                <span className="footIconText">Connect on<br /><strong>WhatsApp</strong></span>
            </div>
            <div className="menu-item">
                <a className="footIcon" onClick={toggleTheme}>{isDarkMode ? <FaIcons.FaRegSun /> : <FaIcons.FaRegMoon />}</a>
                <span className="footIconText">Switch to<br /><strong>{isDarkMode ? 'Light Mode' : 'Dark Mode' }</strong></span>
            </div>
        </div>
    </>
  );
};

export default ToggleBtn;