import React from 'react'
import { Helmet } from "react-helmet-async";
import HeroSection from './HeroSection';
import Services from '../services/Serviceslist';

function Home() {

    // useEffect(() => {
    //     document.title = "Brey Digital - ब्रे डिजिटल"
    // }, []);

    const title = `Brey Digital LLP - Branding, Web Design & Web App Development Agency in Noida | ब्रे डिजिटल`
    const desc = `We’re a design studio focused on creating brands and digital products. We create innovative digital products & future-oriented brands. We design for brands, we work for people.`

    return (
        <div>
            <Helmet>
                <title>{`${title}`}</title>
                <meta name="title" content={`${title}`}/>
                <meta name="description" content={`${desc}`} />
                <meta property="og:title" content={`${title}`}/>
                <meta property="og:description" content={`${desc}`}/>
                <meta property="og:url" content="https://brey.in/"/>
                <link rel="canonical" href="https://brey.in/"/>
                <meta property="og:site_name" content="Brey Digital LLP"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <HeroSection/>
            {/* <section>
                <Services/>
            </section> */}
        </div>
    )
}

export default Home
